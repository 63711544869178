import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface JWTState {
  jwt: string | null;
  setJwt: (jwt: string | null) => void;
  clearJwt: () => void;
}

export const useJwtStore = create<JWTState>()(
  persist(
    (set) => ({
      jwt: null,
      setJwt: (jwt) => set({ jwt }),
      clearJwt: () => set({ jwt: null }),
    }),
    {
      name: 'jwt-storage',
    }
  )
);